<template>
  <b-card title="Snow Editor">
    <b-badge
      variant="primary"
      @click="prova1('{NOME_UTENTE}')"
    >
      {NOME_UTENTE}
    </b-badge>
    <quill-editor
      ref="template-form"
      v-model="content"
      :options="snowOption"
    />

  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
    BCard,
    BBadge,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      content: `<h1 class="ql-align-center">Quill Rich Text Editor</h1>
                    <p class="card-text"><br /></p>
                    <p class="card-text">
                      Quill is a free, <a href="https://github.com/quilljs/quill/">open source</a> WYSIWYG editor built
                      for the modern web. With its
                      <a href="http://quilljs.com/docs/modules/">modular architecture</a> and expressive
                      <a href="http://quilljs.com/docs/api/">API</a>, it is completely customizable to fit any need.
                    </p>
                    <p class="card-text"><br /></p>

                    <p class="card-text"><br /></p>
                    <p class="card-text"><br /></p>
                    <h2 class="ql-align-center">Getting Started is Easy</h2>
                    <p class="card-text"><br /></p>
                    <pre>
// &lt;link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet"&gt;
// &lt;script src="https://cdn.quilljs.com/1.3.6/quill.min.js" type="text/javascript"&gt;&lt;/script&gt;
var quill = new Quill('#editor', {
    modules: {
        toolbar: '#toolbar'
    },
    theme: 'snow'
});
// Open your browser's developer console to try out the API!</pre>`,
    }
  },
  methods: {
    prova() {
      // console.log('ciao')
    },
    prova1(text) {
      const { quill } = this.$refs['template-form']
      // console.log('quill', quill)
      const selection = quill.getSelection(true)
      // console.log(selection)
      quill.insertText(selection.index, text)
    },
  },
}
</script>
